import React from 'react';
import { useInView } from 'react-intersection-observer';

// components
import { VIDEO_PLAYER_TYPE, VideoPlayer } from 'components/ContentElementsGdds/VideoPlayer';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { useUniqueId } from 'utils/hooks/use-unique-id';
import { useThemeName } from 'utils/hooks/use-theme';
import { getContentBoxColor } from './HeroImageVideoPlayer.utils';

// types
import type { HeroImageVideoPlayerProps } from './HeroImageVideoPlayer.types';

// styles
import { StyledHeroImageVideoPlayer } from './HeroImageVideoPlayer.styles';

function HeroImageVideoPlayer(props: Readonly<HeroImageVideoPlayerProps>) {
  const { video } = props;

  const isMobile = useIsMobile();

  const id = useUniqueId();
  const cssId = `player-container-${id}`;

  const { ref: refVideoPlayer, inView } = useInView({
    threshold: 0,
  });

  const themeName = useThemeName();

  if (!video) return null;

  const videoPreviewImage = isMobile ? video.imageObjectMobile.url : video.imageObject.url;

  const _color = getContentBoxColor({ color: video.textbox.color, themeName });

  return (
    <StyledHeroImageVideoPlayer
      className="hero-image-video-player"
      ref={refVideoPlayer}
      color={_color}
    >
      <VideoPlayer
        playerType={VIDEO_PLAYER_TYPE.BACKGROUND}
        video={video.video}
        inView={inView}
        loopType={video.loopType}
        heading={video.textbox.headline}
        subheading={video.textbox.subline}
        button={video.textbox.link}
        color={_color}
        fallbackImg={videoPreviewImage}
        title={video.pictureAlt}
        cssID={cssId}
        hasPreviewId={false}
        position={video.textbox.position}
        animation={true}
      />
    </StyledHeroImageVideoPlayer>
  );
}

export { HeroImageVideoPlayer };
