// types
import type { IMeta } from '../meta.types';

// components
import MetaData from '../MetaData';
import Footer from 'components/Footer/Footer';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

interface LayoutProps {
  metaData: IMeta;
  className?: string;
}

export default function Layout({
  children,
  className,
  metaData,
}: Readonly<React.PropsWithChildren<LayoutProps>>) {
  return (
    <>
      <MetaData {...metaData} />
      <main className={classNameBuilder(className, 'gdds')}>{children}</main>
      <Footer />
    </>
  );
}
