// types
import type { ThemeNameProps } from 'themes/theme.types';
import type { SliderTextColors } from 'components/ContentElements/Slider/Slider.types';

export function getContentBoxColor({
  color,
  themeName,
}: {
  color: SliderTextColors;
  themeName: ThemeNameProps;
}): SliderTextColors {
  const themesWithInvertedContentBoxColor: ThemeNameProps[] = ['NORD', 'KOLO', 'TWYFORD'];
  const _color =
    themesWithInvertedContentBoxColor.includes(themeName) && color === 'white' ? 'black' : 'white';

  return _color;
}
