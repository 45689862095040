import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import isEmpty from 'lodash.isempty';
import { HeroImage } from '@geberit/gdds';
import type { HeroImageItems as GddsHeroImageItems } from '@geberit/gdds/dist/esm/heroimage/heroimage.types';

// styles
import styles from './heroimage.module.scss';

// components
import FullscreenIframe from '../fullscreen-iframe/fullscreen-iframe';
import { HeroImageVideoPlayer } from './components/HeroImageVideoPlayer';

// types
import type { HeroImageGddsProps } from './HeroImageGdds.types';

// utils
import { currentPageSelector } from 'utils/selectors/pageSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useIsMobile } from 'components/App/SizeProvider';
import { getModifiedItems, getVideoSlide } from './HeroImageGdds.utils';

export default function HeroImageGdds(props: Readonly<HeroImageGddsProps>) {
  const { items, isSeoConformCarousel, contentBoxPosition } = props;

  const isItemListAvailable = typeof items !== 'undefined' && items.length > 0;

  const isMobile = useIsMobile();
  const [modifiedItems, setModifiedItems] = useState<GddsHeroImageItems[]>([]);
  const [naviHeight, setNaviHeight] = useState(0);
  const [iframeShown, setIframeShown] = useState<string | null>(null);
  const router = useRouter();
  const language = useCurrentLanguage();
  const translate = useTranslationFunction();
  const page = useSelector(currentPageSelector);
  const track = useTracking();

  useEffect(() => {
    const headerHeight = document.getElementById('page-header')?.offsetHeight;
    setNaviHeight(headerHeight ?? 0);
  }, []);

  useEffect(() => {
    if (isItemListAvailable) {
      const newItems = getModifiedItems({
        items,
        isMobile,
        router,
        track,
        translate,
        setIframeShown,
      });

      setModifiedItems(newItems);
    } else {
      setModifiedItems([]);
    }
  }, [language, isMobile, iframeShown, items, translate, router, track, isItemListAvailable]);

  if (isEmpty(modifiedItems)) return null;

  if (!isItemListAvailable) return null;

  const video = getVideoSlide(items);

  const isNordicsContentPage = page?.type === 'nordics_contentpage';
  const isHomepage = page?.type === 'nordics_homepage' || page?.type === 'kolo_homepage';

  return (
    <div
      className={classNameBuilder(
        styles.heroimage,
        isNordicsContentPage && styles.contentpage,
        isHomepage && styles.homepage,
        'hero-image-gdds',
      )}
    >
      {video ? (
        <HeroImageVideoPlayer video={video} />
      ) : (
        <HeroImage
          type={modifiedItems.length > 1 ? 'b' : 'a'}
          items={modifiedItems}
          previewId={isNordicsContentPage ? '#pt_heroTypeABItems' : undefined}
          previewIdExtractor={
            isNordicsContentPage
              ? (_, index) => {
                  return `#${index}`;
                }
              : undefined
          }
          navigationHeight={naviHeight}
          isSeoConformCarousel={isSeoConformCarousel}
          contentBoxPosition={contentBoxPosition}
        />
      )}

      {iframeShown && <FullscreenIframe src={iframeShown} onClose={() => setIframeShown(null)} />}
    </div>
  );
}
