// styles
import { HiddenH1 } from './home.styles';

// types
import type { GroupHomeProps } from '../Home/home.types';

// components
import Layout from './Layout';
import HeroImageGDDS from 'components/ContentElementsGdds/HeroImage/heroimage-gdds';
import { ContentArea } from 'components/ContentArea/ContentArea';

export function Home({
  contentAreas: { content },
  page: { slider },
  metaData,
  ...props
}: Readonly<GroupHomeProps>) {
  return (
    <Layout metaData={metaData}>
      {metaData.title && <HiddenH1>{metaData.title}</HiddenH1>}
      <HeroImageGDDS {...slider} />
      <ContentArea content={content} contentType="content" {...props} />
    </Layout>
  );
}
