import { Dispatch, SetStateAction } from 'react';
import key from 'weak-key';
import { HeroImageItems as GddsHeroImageItems } from '@geberit/gdds/dist/esm/heroimage/heroimage.types';

// types
import type { ItemProps } from './HeroImageGdds.types';
import type { Link } from 'types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { handlePopup } from 'utils/openInPopup';
import { isEmpty } from 'utils/is-empty';
import { popUpLink } from 'components/Link/trackingActions';
import { NextRouter } from 'next/router';

function isVideoAvailable(item: ItemProps) {
  return !isEmpty(item.video) && !isEmpty(item.video.videoId) && item.heroType === 'bgvideo';
}

export function getVideoSlide(items: ItemProps[]): ItemProps | undefined {
  if (!items || isEmpty(items)) {
    throw new Error('No video available!');
  }

  return items.find(isVideoAvailable);
}

export function getModifiedItems({
  items,
  isMobile,
  router,
  track,
  translate,
  setIframeShown,
}: {
  items: ItemProps[];
  isMobile: boolean;
  router: NextRouter;
  track: {
    trackEvent: (data: any) => void;
  };
  translate: (key: string, fallbackOnPreview?: boolean) => string;
  setIframeShown: Dispatch<SetStateAction<string | null>>;
}): GddsHeroImageItems[] {
  const newItems = items.reduce(
    (
      acc,
      {
        link,
        pictureAlt,
        textColor,
        colorVariant,
        imageObjectMobile,
        imageObject,
        headline,
        subline,
        subheadline,
        emphasize,
        textbox,
      },
    ) => {
      const imageObj = isMobile ? imageObjectMobile : imageObject;
      const boxLink = textbox?.link || link;

      const newCur = {
        image: {
          src: imageObj.url,
          alt: decodingContent(pictureAlt),
          title: decodingContent(pictureAlt),
        },
        color:
          textbox?.color ||
          textColor ||
          (colorVariant && colorVariant === 'light' ? 'white' : 'black'),
        button: boxLink && {
          text: boxLink?.text ?? '',
          onClick: (e) => onButtonClick(e, boxLink, router, track, setIframeShown),
        },
        title: decodingContent(textbox?.headline || headline, true),
        titlePreviewId: '#st_headline',
        subtitlePreviewId: '#st_subline',
        subtitle: decodingContent(textbox?.subline ?? subline ?? subheadline, true),
        emphasize,
        scrollIndicatorText: translate('group_scroll'),
        boxposition: textbox?.position,
      };

      return [...acc, newCur];
    },
    [],
  );

  return newItems;
}

function onButtonClick(e, boxLink: Link, router, track, setIframeShown) {
  if (boxLink?.type === 'internal_link') {
    if (boxLink?.window === '_blank') {
      window.open(boxLink?.target);
    } else {
      router.push(boxLink?.target);
    }
  } else if (boxLink?.type === 'mail_link') {
    window.open(boxLink?.target, '_self');
  } else if (boxLink.window === '_self') {
    window.location.href = boxLink?.target;
  } else if (boxLink.window === '_popup') {
    e.preventDefault();
    track.trackEvent(popUpLink(boxLink?.target));
    const windowId = key(boxLink);
    handlePopup(boxLink, windowId);
  } else if (boxLink.window === '_iframe') {
    e.preventDefault();
    setIframeShown(boxLink?.target);
  } else {
    window.open(boxLink?.target, '_blank');
  }
}
